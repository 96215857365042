<template>
  <div :class="'item ' + (itemData.isStar ? 'start' : '')">
    <div class="icon">
      <img :src="itemData.memberIcon" class="img" />
    </div>
    <div class="info">
      <div class="name option">
        <div class="name-title">
          {{ itemData.memberNickname }}
        </div>
        (匿名评价)
        <div class="gray time">评价时间： {{ formatTime(itemData.createTime) }}</div>
      </div>
      <div class="star option"><el-rate v-model="itemData.allStar" disabled></el-rate></div>
      <div class="content option">
        <div class="key">评价内容：</div>
        <div class="word gray">
          {{ showMore ? itemData.content : itemData.content.substring(0, contentLength)
          }}<span v-if="itemData.content.length > contentLength && !showMore">...</span>
          <span class="showMore" v-if="itemData.content.length > contentLength" @click="showMore = !showMore">{{
            showMore ? '隐藏' : '查看更多'
          }}</span>
        </div>
      </div>
      <div class="option img-list" v-show="showAll && itemData.reviewPhoto.length > 0">
        <div class="img" v-for="(item, index) in itemData.reviewPhoto" :key="index" @click="showCover(index)">
          <i class="el-icon-zoom-in"></i>
          <img :src="item" />
        </div>
      </div>
      <div class="businessName option" v-if="type != 'express'">
        <div class="key">门店名称：</div>
        <div class="word gray">{{ itemData.storeName }}</div>
      </div>
      <div class="productName option">
        <div class="key">商品名称：</div>
        <div class="word gray">{{ itemData.productName }}</div>
      </div>
    </div>
    <div class="handle">
      <div class="show">
        <el-button type="danger" @click="showAllMethod">{{ showAll ? '收起' : '展示' }}</el-button>
        <el-button type="danger" @click="$emit('check', itemData)">查看</el-button>
      </div>
      <div class="click">
        <el-button type="info" plain v-if="itemData.showStatus" @click="$emit('showDelete', { row: itemData })"
          >隐藏</el-button
        >
        <el-button type="primary" plain v-else @click="$emit('showDelete', { row: itemData })">显示</el-button>
        <el-button type="danger" plain @click="$emit('showStar', { row: itemData })"
          >{{ itemData.isStar ? '取消' : '设置' }}优质评论</el-button
        >
      </div>
      <div class="order gray">
        <div class="order-id">订单号：{{ itemData.orderId }}</div>
        <div class="order-time">创建时间：{{ formatTime(itemData.createTime, true) }}</div>
      </div>
    </div>
    <div class="cover" v-if="visibleForCover" @click="visibleForCover = false">
      <div class="img-box" @click.stop>
        <div class="location">
          <img :src="itemData.reviewPhoto[imgIndex]" class="img" />
          <div class="close" @click="visibleForCover = false">
            <i class="el-icon-close"></i>
          </div>
          <div class="handle">
            <i class="el-icon-arrow-left" @click="prev"></i>
            <div class="index">{{ imgIndex + 1 }}/{{ itemData.reviewPhoto.length }}</div>
            <i class="el-icon-arrow-right" @click="next"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatTime } from '@/util/filter'
export default {
  props: ['itemData', 'type'],
  data() {
    return {
      showMore: false, // 是否展示全部评价内容
      showAll: false, // 是否展示图片及评价内容
      imgIndex: 0, // 点中的图片索引
      visibleForCover: false, // 图片蒙层是否展示
      contentLength: 100 // 评价内容长度多少就展示隐藏
    }
  },
  created() {},
  methods: {
    formatTime,
    showAllMethod() {
      // 展示图片列表及其他信息
      this.showAll = !this.showAll
      this.showMore = this.showAll
    },
    showCover(index) {
      // 展示图片蒙层
      this.imgIndex = index
      this.visibleForCover = true
    },
    prev() {
      // 上一个
      if (this.imgIndex === 0) {
        this.$message.error('已经是第一个了')
        return false
      }
      this.imgIndex--
    },
    next() {
      // 下一个
      if (this.imgIndex === this.itemData.reviewPhoto.length - 1) {
        this.$message.error('已经是最后一个了')
        return false
      }
      this.imgIndex++
    }
  }
}
</script>

<style lang="less">
.item {
  display: flex;
  padding: 10px;
  border-bottom: 1px solid #f1f1f1;
  font-size: 13px;
  line-height: 26px;
  font-family: HarmonyOS Sans SC;
  // margin-top: 10px;
  &.start {
    background: oldlace;
  }
  .icon {
    height: 50px;
    width: 42px;
    .img {
      width: 34px;
      height: 34px;
      border-radius: 50%;
    }
  }
  .info {
    flex: 1;
    padding-right: 100px;
    .option {
      display: flex;
      .key {
        width: 70px;
      }
      .word {
        flex: 1;
        .showMore {
          padding-left: 5px;
          color: #0e8bff;
          cursor: pointer;
        }
      }
      .name-title {
        font-size: 16px;
      }
      .time {
        margin-left: 10px;
      }
      &.img-list {
        .img {
          width: 60px;
          height: 60px;
          position: relative;
          cursor: pointer;
          margin-right: 5px;
          .el-icon-zoom-in {
            position: absolute;
            width: 18px;
            height: 18px;
            bottom: 0;
            right: 0;
            color: #fff;
            background-color: rgba(0, 0, 0, 0.5);
            line-height: 20px;
            text-align: center;
            border-radius: 2px;
          }
          img {
            width: 60px;
            height: 60px;
            border-radius: 3px;
          }
        }
      }
    }
  }
  .handle {
    width: 375px;
    text-align: right;
    padding-right: 16px;
    display: flex;
    flex-direction: column;
    .click {
      flex: 1;
      margin-top: 16px;
    }
    .order {
      display: flex;
      justify-content: space-between;
    }
  }
  .gray {
    color: #777;
  }
  .cover {
    position: fixed;
    z-index: 100;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
    .img-box {
      width: 400px;
      height: 400px;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      .location {
        position: relative;
        width: 100%;
        height: 100%;
        .img {
          width: 100%;
          height: 100%;
          border-radius: 5px;
        }
        .close {
          width: 30px;
          height: 30px;
          position: absolute;
          color: #fff;
          background-color: rgba(0, 0, 0, 0.7);
          border-radius: 50%;
          text-align: center;
          line-height: 30px;
          right: 0;
          top: 0;
          font-size: 20px;
          cursor: pointer;
        }
        .handle {
          display: flex;
          flex-direction: row;
          line-height: 34px;
          background-color: rgba(0, 0, 0, 0.6);
          width: 100px;
          color: #fff;
          padding-right: 0;
          border-radius: 17px;
          position: absolute;
          bottom: -50px;
          left: 150px;
          .index {
            flex: 1;
            text-align: center;
            line-height: 32px;
          }
          i {
            width: 34px;
            cursor: pointer;
            line-height: 34px;
            text-align: center;
          }
        }
      }
    }
  }
}
</style>
